import { useState } from "react";
import { Button } from "react-bootstrap";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CustomErrorContainer, ErrorContainer, FormContainer, FormField, FormInput, FormLabel, HeaderContainer, Heading, PersonalInfoContainer, SubHeading } from "../Registration/Style";
import { loadUser, onboardUserSuccess } from "../../actions";
import { validateEmail } from "../../helpers";
import styled from "styled-components";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Verification from "./Verification";
import Cookies from "js-cookie";
import { getCountryCallingCode } from "react-phone-number-input";
import infoIcon from '../../assets/images/info.svg';


const CustomPhoneInput = styled(PhoneInput)`
    width: 100%;
    font-size: 12px;
    font-family: 'Hauora';
    color: var(--dark-text-color);
    font-weight: 400;
    background: white;
    .PhoneInputCountry {
        border: 0.5px solid #b9bcca;
        border-radius: 3px;
        box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.16);
        padding: 0px 14px;
    }
    input {
        display: block;
        padding: 10px 14px;
        font-size: 16px;
        border: 1px solid #cccccc;
        /* Add a border to the input */
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        transition: box-shadow 150ms ease;
        line-height: normal;
        width:100%;   
    }
`;

const UserPersonalInfo = ({ }) => {
    const currentUser = useSelector(state => state.user.currentUser);
    const [userInfo, setUserInfo] = useState({ ...currentUser, password: '', confirmPassword: '' });
    const [countryCode, setCountryCode] = useState(currentUser.countryCode || '1');
    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verification, setVerification] = useState(false);
    const [verificationOf, setVerificationOf] = useState('');
    const [dataError, setDataError] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();

    const onChange = (name, value) => {
        const tempUserInfo = { ...userInfo };
        tempUserInfo[name] = value?.trimStart();
        setUserInfo(tempUserInfo);
    };

    const validateData = () => {
        const error = {};
        if (!userInfo.email && !userInfo.phone) {
            error.phone = 'Email or Phone must be needed.';
            error.email = 'Email or Phone must be needed.';
            error.in_valid = true;
        } else {
            if (userInfo.email && !validateEmail(userInfo.email)) {
                error.email = 'Invalid email address.';
                error.in_valid = true;
            };
            if (userInfo.phone && !isValidPhoneNumber(userInfo.phone)) {
                error.phone = 'Invalid phone.';
                error.in_valid = true;
            };
        };
        const passPattern = /^(?=.*[0-9])(?=.*[!@#$%^&.,_*])[a-zA-Z0-9!@#$%^&.,_*]{8,16}$/;
        if (!passPattern.test(userInfo.password)) {
            error.password = `Passwords must contain \n\u2022 a minimum of 1 lower case letter, \n\u2022 1 upper case letter, \n\u2022 1 numeric character \n\u2022 1 special character and \n\u2022 at least 8 characters in length.`;
            error.in_valid = true;
        } else if (userInfo.password !== userInfo.confirmPassword) {
            error.password = 'Password and confirm password does not match.';
            error.in_valid = true;
        };
        setDataError(error);
        return error.in_valid;
    };

    const checkEmailPresent = async (email) => {
        try {
            if (userInfo.email && validateEmail(userInfo.email)) await axios.post('/api/v2/checkEmailId', { email, _id: currentUser._id });
        } catch (error) {
            setDataError({ email: 'User email id already present.' });
            throw error;
        }
    };

    const checkPhonePresent = async () => {
        try {
            if (userInfo.phone && isValidPhoneNumber(userInfo.phone)) await axios.post('/api/v2/checkPhoneId', { phone: userInfo.phone, _id: currentUser._id, countryCode });
        } catch (error) {
            setDataError({ phone: 'User phone already present.' });
            throw error;
        }
    };

    const sendOptOnEmail = async () => {
        try {
            const { email, firstName } = userInfo;
            return await axios.post('/api/v2/sendOtpOnEmail', { email, firstName });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const sendOptOnPhone = async () => {
        try {
            const { phone, firstName } = userInfo;
            return await axios.post('/api/v2/sendOtpOnPhone', { phone, firstName });
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            try {
                const in_valid = validateData();
                if (!in_valid) {
                    setLoading(true);
                    console.log(userInfo);
                    const tempVar = (userInfo.email && userInfo.phone) ? 'both' : userInfo.email ? 'email' : 'phone';
                    setVerificationOf(tempVar);
                    setDataError({});
                    if (userInfo.email) {
                        await checkEmailPresent(userInfo.email);
                        await sendOptOnEmail();
                    };
                    if (userInfo.phone) {
                        await checkPhonePresent();
                        await sendOptOnPhone();
                    };
                    setVerification(true);
                    setLoading(false);
                };
            } catch (error) {
                setLoading(false);
            }
        };
    };

    const verifyAndOnboard = async () => {
        try {
            const { data } = await axios.post(`/api/v2/users/onboard/${userInfo._id}`, { firstName: userInfo.firstName, lastName: userInfo.lastName, email: userInfo.email, phone: userInfo.phone, password: userInfo.password, _id: userInfo._id, isRegistrationCompleted: 2, safetyEquipments: userInfo.safetyEquipments ? userInfo.safetyEquipments : [], countryCode });
            axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
            await dispatch(onboardUserSuccess(data));
            await dispatch(loadUser(data));
            history.push('/');
        } catch (error) {
            throw error;
        }
    };

    const countryChange = (e) => {
        console.log("===>>", getCountryCallingCode(e));
        setCountryCode(getCountryCallingCode(e));
        // this.setState({ countryCode: getCountryCallingCode(e) });
    };
    return (
        !verification ?
            <PersonalInfoContainer>
                <HeaderContainer>
                    <Heading>Setup your account</Heading>
                    <SubHeading>Complete the form to setup your Ocoord account</SubHeading>
                </HeaderContainer>
                <FormContainer noValidate validated={validated} onSubmit={onSubmit}>
                    <FormField>
                        <FormLabel>First Name</FormLabel>
                        <FormInput required onChange={(e) => onChange('firstName', e.target.value)} value={userInfo.firstName} type="text" placeholder="Enter your first name" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </FormField>
                    <FormField>
                        <FormLabel>Last Name</FormLabel>
                        <FormInput required onChange={(e) => onChange('lastName', e.target.value)} value={userInfo.lastName} type="text" placeholder="Enter your last name" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </FormField>
                    <FormField>
                        <FormLabel>Work Email</FormLabel>
                        <FormInput onBlur={(e) => checkEmailPresent(e.target.value)} required={false} onChange={(e) => onChange('email', e.target.value)} value={userInfo.email} type="email" placeholder="Enter your work email" />
                        {dataError.email &&
                            <CustomErrorContainer className="text-danger">{dataError.email}</CustomErrorContainer>
                        }
                    </FormField>
                    <FormField>
                        <FormLabel>Phone</FormLabel>
                        <CustomPhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry={'US'}
                            countryCallingCodeEditable={false}
                            international={true}
                            autoComplete='off'
                            placeholder="Enter phone number"
                            onChange={(e) => onChange('phone', e)}
                            value={userInfo.phone}
                            onCountryChange={((e) => countryChange(e))}
                            onBlur={(e) => checkPhonePresent(e)}
                        />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                        {dataError.phone &&
                            <CustomErrorContainer className="text-danger">{dataError.phone}</CustomErrorContainer>
                        }
                        <FormField>
                            <h5 className='text-success my-3'> <img src={infoIcon} className='mr-2' />Either a email or phone number is required</h5>
                        </FormField>
                    </FormField>
                    <FormField>
                        <FormLabel>Password</FormLabel>
                        <FormInput required onChange={(e) => onChange('password', e.target.value)} value={userInfo.password} type="password" placeholder="Enter your password" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                    </FormField>
                    <FormField>
                        <FormLabel>Confirm Password</FormLabel>
                        <FormInput required onChange={(e) => onChange('confirmPassword', e.target.value)} value={userInfo.confirmPassword} type="password" placeholder="Confirm your password" />
                        <ErrorContainer type="invalid">Required</ErrorContainer>
                        {dataError.password &&
                            <CustomErrorContainer className="text-danger">{dataError.password}</CustomErrorContainer>
                        }
                    </FormField>
                    <FormField>
                        <Button disable={loading} type='submit' size="lg" className="w-100 mt-4" variant="success">{loading ? 'Loading...' : 'Next'}</Button>
                        <Button disable={loading} onClick={() => history.goBack()} size="lg" className="w-100 mt-4" variant="light">Cancel</Button>
                    </FormField>
                </FormContainer>
            </PersonalInfoContainer>
            :
            <Verification userInfo={userInfo} verifyAndOnboard={verifyAndOnboard} sendOptOnEmail={sendOptOnEmail} sendOptOnPhone={sendOptOnPhone} verificationOf={verificationOf} />
    )
};

export default UserPersonalInfo;
import actions from './actionTypes';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import axios from 'axios';
import { apiUrl } from '../helpers';

/**
 * @namespace taskActions
 * @category Actions
 */

/**
 * @method
 * @memberOf taskActions
 * @param {*} tasks
 */
const getTasksSuccess = (tasks) => ({
  type: actions.GET_TASKS_SUCCESS,
  tasks
});

/**
 * @method
 * @memberOf taskActions
 * @param {*}
 */
const getTasksFailure = () => ({
  type: actions.GET_TASKS_FAILURE
});

/**
 * @method
 * @memberOf taskActions
 * @param {*} task
 */
const completeTasksSuccess = (task) => ({
  type: actions.COMPLETE_TASK_SUCCESS,
  task
});

/**
 * @method
 * @memberOf taskActions
 * @param {*}
 */
const completeTasksFailure = () => ({
  type: actions.COMPLETE_TASK_FAILURE
});

/**
 * @method
 * @async
 * @memberOf taskActions
 * @param {*} locationId
 */
export const getTasks = (locationId) => async (dispatch, getState) => {
  try {
    const {
      user: { currentUser }
    } = getState();

    let tasksObj = {};
    const { data } = await axios.get(apiUrl(`/api/v1/tasks?locationId=${locationId}&userId=${currentUser._id}`));

    tasksObj.dueToday = data.filter((task) => dayjs(task.due_date).isSame(dayjs(), 'day'));
    tasksObj.dueOnce = data.filter((t) => t.frequency === '99' && !tasksObj.dueToday.find((ta) => ta._id === t._id) && !t.lastCompletedAt);

    tasksObj.dueThisWeek = data.filter((task) => {
      return (
        dayjs(task.due_date).isSame(dayjs(), 'week') &&
        !tasksObj.dueToday.map((i) => i._id).includes(task._id) &&
        task.frequency !== '99' && task.frequency === '0'
      );
    });
    tasksObj.dueThisMonth = data.filter((task) => {
      return (
        dayjs(task.due_date).isSame(dayjs(), 'month') &&
        !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) &&
        !tasksObj.dueToday.map((i) => i._id).includes(task._id) &&
        task.frequency !== '99' && task.frequency === '1'
      );
    });
    tasksObj.quarterly = data.filter((task) => {
      return (
        dayjs(task.due_date).isSame(dayjs().add(3, 'month'), 'month') &&
        !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) &&
        !tasksObj.dueToday.map((i) => i._id).includes(task._id) &&
        task.frequency !== '99' && task.frequency === '2'
      );
    });
    tasksObj.dueThisYear = data.filter((task) => {
      return (
        dayjs(task.due_date).isSame(dayjs(), 'year') &&
        !tasksObj.dueThisMonth.map((i) => i._id).includes(task._id) &&
        !tasksObj.dueThisWeek.map((i) => i._id).includes(task._id) &&
        !tasksObj.dueToday.map((i) => i._id).includes(task._id) &&
        task.frequency !== 99 && task.frequency === '3'
      );
    });
    dispatch(getTasksSuccess(tasksObj));
  } catch (err) {
    dispatch(getTasksFailure());
    if (err.response.status !== 401) {
      toast.error(`error in getTasks: ${err}`);
    }
  }
};

/**
 * @method
 * @async
 * @memberOf taskActions
 * @param {*} task, newReport
 */
export const completeTask = (task, newReport) => async (dispatch, getState) => {
  try {
    const { user } = getState();
    newReport && (task.formData = JSON.stringify(newReport.formData));
    const newTaskObj = {
      ...task,
      isCompleted: true,
      completedBy: { _id: user.currentUser._id },
      isApproved: user.currentUser.isAdmin
    };
    if (!newTaskObj.location) newTaskObj.location = user.currentUser.location._id;
    await axios.put(apiUrl(`/api/v1/tasks/${task._id}`), newTaskObj);
    dispatch(completeTasksSuccess(newTaskObj));
    dispatch(getTasks(user.currentUser.location._id));
  } catch (err) {
    console.log("🚀 ~ completeTask ~ err:", err)
    dispatch(completeTasksFailure());
    toast.error(
      `error in completeTask: ${err.response ? err.response.data.message : 'An Error Ocurred'
      }`
    );
  }
};


import { useSelector } from "react-redux";
import DynamicNotification from "./DynamicNotification";
import { DashboardContainer } from "./Style";
import TaskDashboard from "./Tasks";
import { Alert } from "react-bootstrap";

const Dashboard = () => {
    const currentUser = useSelector(state => state.user.currentUser);
    return (
        <DashboardContainer>
            <DynamicNotification />

            {currentUser.location.didCompleteLibrarySetup ? <TaskDashboard /> :
                <div>
                    <Alert id='error-alert' className='my-4' style={{ 'maxWidth': '98%' }} variant="info">
                        <h3 className='w-100 my-2 text-center'>Complete Library Set Up in order for your Compliance Tasks to be generated.</h3>
                    </Alert>
                </div>
            }
        </DashboardContainer>
    )
}

export default Dashboard;
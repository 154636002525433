import { Col, Form } from 'react-bootstrap';
import styled from 'styled-components';

export const Stepper = styled.div`
    margin-top: 3rem;
    background:#FCFCFC;
    display: flex;
    width: 80%;
    justify-content: space-between;
`;

export const StepIcon = styled.div`
    background: ${({ status }) => (status === 'completed' ? '#E7F7ED' : status === 'pending' ? '#cccdcf87' : '#42a764')};
    border-radius: 12px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:2;
    ${({ status }) => (status === 'current' && "box-shadow: 0px 0px 0px 4px rgba(198, 224, 208, 1.00)")}
`;

export const Dot = styled.div`
    background: #fcfcfc;
    border-radius: 50%;
    width: 8px;
    height: 8px;
`;

export const Line = styled.div`
    width: 50%;
    height: 2px;
    align-self: center;
    background: ${({ status }) => (status === 'completed' ? '#E7F7ED' : '#cccdcf87')};
`;

export const StepContainer = styled.div`
    width: 50%;
    text-align: center;
`;

export const ProgressContainer = styled.div`
    display: flex;
`;

export const StepTitleContainer = styled.div`
    text-align: center;
    margin-top:1rem;
    margin-bottom:1rem;
`;

export const StepTitle = styled.p`
    color: ${({ status }) => (status === 'current' ? '#42a764' : '#161616')};
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0px;
`;

export const StepSubTitle = styled.p`
    color: ${({ status }) => (status === 'current' ? '#42a764' : '#7e818f')}; 
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 0px;
`;

export const RegisterComponentContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - 8rem);
    padding: 15px;
    margin: 4rem;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);
`;

export const PersonalInfoContainer = styled.div` 
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 1px 1px 4px 1px rgb(0 0 0 / 10%);
    padding: 25px;
    margin-top: 5rem;
    width: 30%;
    .cursor-pointer{
        cursor: pointer;
    }
    @media (max-width: 1300px) {
        width: 90%;
    }
 `;

export const FormContainer = styled(Form)`
 
`;

export const FormField = styled.div`
    margin-bottom:1rem;
`;

export const FormInput = styled.input`
 display: block;
 padding: 7px 14px;
 font-size: 16px;
 border: 1px solid #cccccc;
 /* Add a border to the input */
 border-radius: 4px;
 box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
 transition: box-shadow 150ms ease;
 line-height: normal;
 width:100%;
`;

export const FormLabel = styled.label`
color:  #161616;
text-align: left;
font-size: 12px;
font-weight: 400;
`;

export const HeaderContainer = styled.div`

`;

export const Heading = styled.p`
    color: #4fb973;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0px;
`;

export const SubHeading = styled.p`
    color: #565656;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0px;
`;

export const OtpContainer = styled.div`
display: flex;
justify-content: center;
margin-top: 3rem;
.otpInput {
    width: 50px !important;
    height: 50px !important;
    background: #fcfcfc;
    border-radius: 5px;
    padding: 10px;
    color: #4fb973;
    font-weight: 700;
    @media (max-width: 425px) {
        width: 30px !important;
        height: 30px !important;
    }
}
`;

export const CompanyInfoContainer = styled(PersonalInfoContainer)`
    width: 60%;
`;

export const SectionTitle = styled.div`
    color: #3c3c3c;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #d3d6dd;
`;

export const BillingContainer = styled.div`

`;

export const BillingProductContainer = styled(Col)`
    background-color: #F9F9F9;
    width: 90%
    padding: 30px;
    @media (max-width: 991px) {
        width: 100% !important;
    }
`;

export const ProductCard = styled(PersonalInfoContainer)`
    width:100%
    margin-top: 2rem;
    padding: 1rem;
    ${({ selected }) => selected && "border : 2px solid ##4fb973 !important;    box-shadow: 1px 1px 4px 1px #4fb973;"}
    
    @media (max-width: 991px) {
        width: 100% !important;
    }
`;

export const ProductCardTitleContainer = styled.div`
    border-bottom: 1px solid #d3d6dd;
`;

export const ProductCardTitle = styled.p`
    color: #343747;
    text-align: left;
    font-size: 20px;
    font-weight: 600;
`;

export const ProductCardSubTitle = styled.p`
    color: #7e818f;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
`;

export const PaymentInfoContainer = styled(PersonalInfoContainer)`
    width: 90%
    margin-top: 0rem;
    padding: 30px;
    @media (max-width: 991px) {
        width: 100% !important;
    }
.StripeElement {
    display: block;
    padding: 13px;
    font-size: 16px;
    border: 1px solid #ccc;
    /* Add a border to the input */
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: box-shadow 150ms ease;
}

.StripeElement--focus {
    border-color: #4fb973;
    /* Change border color when the input is focused */
    box-shadow: 0 0 0 2px rgba(79, 185, 115, 0.30);
}
`;

export const PriceSubTotal = styled.p`
    text-align: center;
    text-decoration: line-through;
    align-items: center;
    color: #656772;
    font-size: 18px;
    margin-bottom:0px;
`;

export const PriceTotal = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center !important;
    color: #343747;
    font-size: 25px;
    font-weight: 600;
`;

export const ErrorContainer = styled(Form.Control.Feedback)`
    font-weight: 600 !important;
    margin-top: 0rem;
    font-size: 1rem !important;
    font-family: Hauora;
`;

export const CustomErrorContainer = styled.pre`
    font-weight: 600 !important;
    margin-top: 0rem !important;
    font-size: 1rem !important;
    font-family: Hauora;
`;



export const Chip = styled.div`
    justify-content: space-between;
    display: flex;
    min-width: 50%;
    // max-width: 75%;
    font-family: 'Hauora';
    background: #98e7bf;
    border: 0.1px solid #b9bcca;
    border-radius:10px;
    padding: 5px 10px;
    box-shadow: inset 0 0.2px 0.2px 0 rgba(0, 0, 0, 0.16);
    span{
        font-size:16px;
        font-weight:600;
    };
    i{
        font-Size:18px;
        margin: auto 0px;
        color:#fd0f0f;
        cursor:pointer;
    }
`;

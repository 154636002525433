import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { CircularProgressbar } from 'react-circular-progressbar';

export class TrainingPercentage extends Component {
  state = {
    percentage: 0,
    trainingDeadline: ''
  };

  componentDidMount() {
    const { user } = this.props;
    setTimeout(() => {
      this.setState({ percentage: (user.trainingMinutesWatched / 60 / 60) * 100 });
    }, 0);

    this.interval = setInterval(() => {
      const { user } = this.props;
      this.setState({ percentage: (user.trainingMinutesWatched / 60 / 60) * 100 });
    }, 1000);
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  };

  render() {
    const { user, match } = this.props;
    const { percentage } = this.state;
    const trainingDeadline = dayjs(user.trainingMinutesResetAt ? user.trainingMinutesResetAt : user.createdAt).add(1, 'year').format('MMMM D, YYYY');
    return (
      <React.Fragment>
        <CircularProgressbar
          value={percentage > 100 ? 100 : percentage}
          text={`${percentage > 100 ? 100 : percentage.toFixed(0)}%`}
          strokeWidth={10}
          styles={{
            path: {
              stroke: `#4fb973`,
              strokeLinecap: 'butt'
            },
            text: {
              fill: '#4fb973',
              fontSize: '25px',
              fontWeight: 'bold'
            }
          }}
        />
        <div className='training-container'>
          {!this.props.hideTime && (
            <h2>
              {user.trainingMinutesWatched > 60 ? 60 : user.trainingMinutesWatched.toFixed(2)} Minutes <span>/ 60</span>
            </h2>
          )}
          <p>
            {this.props.text ||
              `You must watch at least 60 minutes of training by ${trainingDeadline}`}
          </p>
          {match.path !== '/training' && (
            <Link to='/training'>
              {user.trainingMinutesWatched === 0 ? 'Begin' : 'Continue'} Training
            </Link>
          )}
        </div>
      </React.Fragment>
    );
  }
}

import axios from 'axios';
import { io } from 'socket.io-client';
import actions from './actionTypes';
import { apiUrl } from '../helpers';
import { getTasks } from './tasksActions';

export const initSocket = (userId) => async (dispatch, getState) => {
    const {
        user: { currentUser }
    } = getState();
    const socket = await io({ query: { userId } });
    console.info('Connected to the socket server');
    socket.on("get_count", () => {
        dispatch(getNotificationCount());
        dispatch(loadNotificationList());
    });
    socket.on("connect_error", (err) => {
        console.error(`connect_error due to ${err.message}`);
    });
    socket.on("get_task", () => {
        dispatch(getTasks(currentUser.location._id));
    });
    return socket;
};

const loadNotificationListFailure = () => ({
    type: actions.LOAD_NOTIFICATION_LIST_FAILURE
});

const loadNotificationListSuccess = (list) => ({
    type: actions.LOAD_NOTIFICATION_LIST_SUCCESS,
    list
});

const loadNotificationCountFailure = () => ({
    type: actions.LOAD_NOTIFICATION_COUNT_FAILURE
});

const loadNotificationCountSuccess = (count) => ({
    type: actions.LOAD_NOTIFICATION_COUNT_SUCCESS,
    count
});

export const loadNotificationList = () => async (dispatch, getState) => {
    try {
        const {
            user: { currentUser }
        } = getState();
        const { data: { list } } = await axios.get(apiUrl('/api/v1/notification/list/' + currentUser.location._id));
        await dispatch(getNotificationCount());
        await dispatch(loadNotificationListSuccess(list));
        return list;
    } catch (error) {
        dispatch(loadNotificationListFailure());
        return false;
    }
};

export const updateNotificationStatus = (notificationIds) => async (dispatch) => {
    try {
        await axios.put(apiUrl('/api/v1/notification/updateStatus'), { notificationIds });
        await dispatch(loadNotificationList());
        return list;
    } catch (error) {
        dispatch(loadNotificationListFailure());
        return false;
    }
};

export const getNotificationCount = () => async (dispatch, getState) => {
    try {
        const {
            user: { currentUser }
        } = getState();
        const { data: { count } } = await axios.get(apiUrl('/api/v1/notification/count/' + currentUser.location._id));
        await dispatch(loadNotificationCountSuccess(count));
        return count;
    } catch (error) {
        dispatch(loadNotificationCountFailure());
        return false;
    }
};
import { useEffect, useState } from "react";
import { Container, Modal, Spinner } from "react-bootstrap"
import axios from "axios";
import closeIcon from '../../assets/images/glyph-close-gray.svg';
import phoneIcon from '../../assets/images/phone.svg';
import userIcon from '../../assets/images/user.svg';
import clinicIcon from '../../assets/images/clinic.svg';
import locationIcon from '../../assets/images/location.svg';
import relationIcon from '../../assets/images/relation.svg';


import { BodyContainer, BodyRow, BodyRowItem, TitleRow } from "./StyleComponents";

const EmergencyContact = ({ show, close, user }) => {
    const [userInfo, setUserInfo] = useState({ localEmergencyContactName: '', relationWithLocalEmergencyContactName: '', localEmergencyContactPhone: '', hospitalPref: '', familyPhysician: '', pharmacyName: '', allergies: '', religiousOrg: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDetails();
    }, []);

    const getDetails = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/users_emergency_contact/' + user._id);

            setUserInfo(payload);
            setLoading(false);
        } catch (error) {
            console.log("🚀 ~ getDetails ~ error:", error);
            setLoading(false);
        }
    }
    return (
        <Modal
            centered
            animation={true}
            backdrop="static"
            keyboard={false}
            size='lg'
            contentClassName="p-3"
            show={show}>
            <Modal.Header>
                <Modal.Title className="w-100">
                    <div className="d-flex justify-content-between">
                        <span><b>Emergency Contact</b></span>
                        <span onClick={() => close(false)} className="mr-2 cursor-pointer"> <img src={closeIcon} alt="" /> </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto mb-3">
                {loading ?
                    <Container className='text-center my-3'>
                        <Spinner animation="border" variant="success" />
                    </Container>
                    :
                    <div style={{ maxHeight: '70vh', }}>
                        <TitleRow>Main Contact</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>{userInfo.localEmergencyContactName || '--'}</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={relationIcon} alt="" className="mr-2" />Relationship </BodyRowItem>
                                <BodyRowItem>{userInfo.relationWithLocalEmergencyContactName || '--'}</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>{userInfo.localEmergencyContactPhone || '--'}</BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Physician Contact</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={clinicIcon} alt="" className="mr-2" />Company </BodyRowItem>
                                <BodyRowItem>{userInfo.hospitalPref || '--'}</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>{userInfo.familyPhysician || '--'}</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>--</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={locationIcon} alt="" className="mr-2" />Address </BodyRowItem>
                                <BodyRowItem>--</BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Pharmacy</TitleRow>
                        <BodyContainer>
                            <BodyRow>
                                <BodyRowItem><img src={userIcon} alt="" className="mr-2" />Name </BodyRowItem>
                                <BodyRowItem>{userInfo.pharmacyName || '--'}</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={phoneIcon} alt="" className="mr-2" />Phone </BodyRowItem>
                                <BodyRowItem>--</BodyRowItem>
                            </BodyRow>
                            <BodyRow>
                                <BodyRowItem><img src={locationIcon} alt="" className="mr-2" />Address </BodyRowItem>
                                <BodyRowItem>--</BodyRowItem>
                            </BodyRow>
                        </BodyContainer>
                        <TitleRow className="mt-5">Allergies</TitleRow>
                        <BodyRow>
                            <BodyRowItem>Name </BodyRowItem>
                            <BodyRowItem>{userInfo.allergies || '--'}</BodyRowItem>
                        </BodyRow>
                        <TitleRow className="mt-5">Religious Organization</TitleRow>
                        <BodyRow>
                            <BodyRowItem>Name </BodyRowItem>
                            <BodyRowItem>{userInfo.religiousOrg || '--'}</BodyRowItem>
                        </BodyRow>
                    </div>}
            </Modal.Body>
        </Modal>
    )
}

export default EmergencyContact;
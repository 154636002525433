const { useState, useEffect, useRef } = require("react");
import axios from "axios";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
const { Player, ControlBar, ReplayControl, VolumeMenuButton, CurrentTimeDisplay, RemainingTimeDisplay, TimeDivider, DurationDisplay, FullscreenToggle, Shortcut, PlayToggle } = require("video-react");
import 'video-react/dist/video-react.css';

import { apiUrl } from "../../helpers";
import defaultPoster from '../../assets/images/training_video_bg_big.png';
import { Alert, Button, Spinner } from "react-bootstrap";
import { ConformationModal } from "../../components";

const TrainingContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 15px;
    z-index:0;
    justify-content: start;
    margin: 0 0rem 0 4rem;
    align-self: flex-start;
    align-items: flex-start;
    background-color:#fff;
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 10%);

    @media screen and (max-device-width: 4000px) and (orientation: portrait) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width:85%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width:4000px) and (orientation: landscape) {
        margin: 6.5rem 0.5rem 0rem 6rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }

    @media screen and (max-device-width: 767px) and (orientation: portrait) {
        margin:6.5rem 0.5rem 0rem 6rem !important;
        max-width:100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
    @media screen and (max-device-width: 767px) and (orientation: landscape) {
        margin: 6.5rem 1rem 0rem 6.5rem !important;
        max-width: 100%;
        display: block;
        padding: 0;
        overflow: auto;
    }
`;

const TrainingBody = styled.div`
    .fade-in {
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }
      
    .fade-out {
        opacity: 0;
        transition: opacity 0.5s ease-in-out;
    }
`;

const HeaderContainer = styled.div`
    display:flex;
    justify-content: space-between;
`;

const HeaderText = styled.h2`
   
`;

const PlayerContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const TrainingView = () => {
    const history = useHistory();
    const videoRef = useRef(null);
    const user = useSelector(state => state.user.currentUser);
    const [videoData, setVideoData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showConformationModal, setShowConformationModal] = useState(false);
    const [nextVideos, setNextVideos] = useState([]);
    let lastDataSaveTime = 1;

    useEffect(() => {
        getTrainingVideos();
    }, []);

    const getTrainingVideos = async () => {
        try {
            setLoading(true);
            const { data: { payload } } = await axios.get('/api/v2/training/' + user._id);

            const { location } = history;
            const searchParams = new URLSearchParams(location.search);
            const videoId = searchParams.get('videoId');
            let viData = payload.all.find(video => video.eTag === videoId);
            viData.totalCount = 0;

            payload[viData.trainingType].forEach((video, index) => {
                if (video.eTag === videoId) {
                    let next = index === payload.all.length - 1 ? 0 : index + 1;
                    setNextVideos(payload.all[next]);
                } else {
                    viData.totalCount += video.currentTime || 0;
                }
            });

            viData.name = viData.name.replaceAll('/', '~|~');
            if (viData.completed) viData.currentTime = 0;
            const { data } = await axios.get(`/api/v2/trainingVideo/` + encodeURIComponent(viData.name));
            viData.url = data;
            lastDataSaveTime = viData.currentTime || 0;
            setVideoData(viData);
            setLoading(false);
            getCurrentTime(viData);
        } catch (error) {
            setError('Unexpected Error. An unexpected error has occurred. Please try reloading the page. If the problem persists, please contact Ocoord support.');
            setLoading(false);
        }
    };

    const handlePlayerRef = (state, _, viData) => {
        if (lastDataSaveTime < state.currentTime - 10 || state.ended) {
            const progressPercentage = Number(((state.currentTime / state.duration) * 100).toFixed(2));

            const dataToUpdate = {
                trainingType: viData.trainingType,
                videoId: viData.eTag,
                currentTime: Number((state.currentTime).toFixed(2)),
                duration: state.duration,
                progressPercentage: progressPercentage >= 99 ? 100 : progressPercentage,
                totalCount: viData.totalCount + state.currentTime,
                currentUser: user
            };

            axios.put('/api/v2/training/' + user._id, dataToUpdate);
            if (state.ended && !showConformationModal) {
                setShowConformationModal(true);
                videoRef.current.pause();
            };

            lastDataSaveTime = state.currentTime;
        };
    };

    const getCurrentTime = (viData) => {
        if (videoRef.current && !viData.completed) {
            // subscribe state change
            videoRef.current.subscribeToStateChange((state, prevState) => handlePlayerRef(state, prevState, viData));
        };
    };

    const onModalClose = async () => {
        setShowConformationModal(false);
        history.goBack();
    };

    const goNext = () => {
        history.push('/training/view?videoId=' + encodeURIComponent(nextVideos.eTag));
        getTrainingVideos();
    };

    return (
        <TrainingContainer>
            {loading ?
                <div className='w-100 my-3 p-4 text-center'>
                    <Spinner animation="border" variant="success" />
                </div>
                :
                error ?
                    <Alert id='error-alert' className='mt-2 mb-3' style={{ 'maxWidth': '100%' }} variant="danger">
                        <p className='w-100 mb-0'>{error}</p>
                    </Alert>
                    :
                    <TrainingBody>
                        <HeaderContainer className="pt-3 mx-5">
                            <HeaderText>{videoData.title}</HeaderText>
                            <div className="d-flex justify-content-end">
                                <Button onClick={() => history.push('/training')} variant="light" type='button'>{'<< Back'}</Button>
                                <Button onClick={goNext} className="ml-3" variant="success" type='button'>{'Next >>'}</Button>
                            </div>
                        </HeaderContainer>
                        <hr className="mx-5" />
                        <PlayerContainer className="pb-2">
                            <Player
                                aspectRatio='16:9'
                                fluid={false}
                                width={window.screen.width - 180}
                                height={window.screen.height - 300}
                                autoPlay={false}
                                startTime={videoData.currentTime}
                                ref={videoRef}
                                controls={false}
                                src={videoData.url}
                                muted={false}
                                poster={videoData.poster ? apiUrl('/api/v2/trainingPoster/' + encodeURIComponent(videoData.poster.replaceAll('/', '~|~'))) : defaultPoster}
                            >
                                <ControlBar
                                    disableDefaultControls={true}
                                    autoHide={true}>
                                    <PlayToggle key="play-toggle" order={1} />
                                    <ReplayControl seconds={5} order={2.1} />
                                    <ReplayControl seconds={10} order={2.2} />
                                    <ReplayControl seconds={30} order={2.3} />
                                    <VolumeMenuButton key="volume-menu-button" order={4} />
                                    <CurrentTimeDisplay key="current-time-display" order={5} />
                                    <TimeDivider key="time-divider" order={6} />
                                    <DurationDisplay key="duration-display" order={7} />
                                    <RemainingTimeDisplay key="remaining-time-display" order={9} />
                                    <FullscreenToggle key="fullscreen-toggle" order={11} />
                                </ControlBar>
                                <Shortcut clickable={false} shortcuts={[{ keyCode: 37, handle: () => { } }, { keyCode: 39, handle: () => { } }]} />
                            </Player>
                        </PlayerContainer>
                    </TrainingBody>
            }
            {showConformationModal && <ConformationModal show={showConformationModal} videoData={videoData} onClose={onModalClose} user={user} />}
        </TrainingContainer>
    )
}

export default TrainingView;